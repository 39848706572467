'use strict';

angular.module('appApp')
  .directive('primoRadio', function () {
    return {
      templateUrl: 'components/primo-radio/primo-radio.html',
      restrict: 'EA',
      scope: {
        model: '=model'
      },
      //link: function (scope, element, attrs) { }
      link: function () { }
    };
  });
